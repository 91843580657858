.remodal-print {
	max-width: 582px;
	/* position: absolute; */
	/* top: 110px; */
	/* left: 50%; */
	/* transform: translateX(-50%); */
	padding-left: 50px;
	padding-top: 20px;
	background-color: white;
	border-radius: 6px;
}

.print-receipt-title h2 {
	color: #353638;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 21px;
	font-weight: 500;
	margin-bottom: 25px;
}

.print-receipt-title .form-group {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	max-width: 400px;
	margin-right: auto;
	margin-bottom: 10px;
}


.print-receipt-title form label {
	color: #353638;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 17px;
	font-weight: 400;
	line-height: 26px;
}

.print-receipt-title form select {
	color: #9a9a9b;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 14px;
	font-weight: 400;
	width: 137px;
	height: 37px;
	border: 1px solid rgba(164, 164, 164, 0.3);
	border-radius: 5px;
	padding-left: 5px;
}

.print-receipt-title form option {
	height: 37px;
}

.print-button {
	margin-top: 30px;
}

.print-button a {
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 15px;
	font-weight: 500;
	width: 205px;
	height: 38px;
	background-color: #1c5bae;
	border-radius: 19px;
	border: 1px solid #1c5bae;
	margin-left: auto;
	margin-right: auto;
}

@media (width <= 641px) {
	.remodal-print {
		margin-left: auto;
		margin-right: auto;
		padding-left: 30px;
	}
}

@media (width <= 479px) {
	.remodal-print {
		padding-left: 20px;
		padding-right: 20px;
	}
}