.personal-account-alert {
	padding: 15px;
	background-color: #ffe7e5;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 100%;
	height: auto;
	@media (width <= 991px) {
		min-height: 90px;
		height: 90px;
		padding-top: 0;
		padding-bottom: 0;
	}
	@media (width <= 641px) {
		height: 120px;
	}
	@media (width <= 479px) {
		height: 150px;
	}
}

.personal-account-alert ~ .general-content__wrapper .general-sidebar__wrapper {
	@media (width <= 991px) {
		top: 173px;
	}
	@media (width <= 641px) {
		top: 246px;
	}
	@media (width <= 479px) {
		top: 296px;
	}
}

.personal-account-alert svg {
	vertical-align: bottom;
	width: 22px;
	height: 20px;
	margin-right: 10px;
}

.personal-account-alert span {
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 15px;
	font-weight: 700;
	line-height: normal;
	text-align: center;
}

.personal-account-alert span {
	color: #353638;
	margin-right: 5px;
}

.personal-account-alert__link {
	color: #0782c1;
	border-bottom: 1px solid currentColor;
	white-space: nowrap;
	margin-left: 5px;
}