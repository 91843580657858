.reporting-period-info__wrapper {
	width: 100%;
	padding-left: 35px;
	padding-right: 35px;
	padding-bottom: 35px;
	padding-top: 30px;
}

.reporting-period-title h2 {
	text-align: center;
	margin-bottom: 25px;
	color: #353638;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 21px;
	font-weight: 500;
}

.reporting-period-select {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.reporting-period-select .period {
	flex-basis: calc(25% - 10px);
	max-width: calc(25% - 10px);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	width: 250px;
	height: 100px;
	background-color: white;
	color: #353638;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 17px;
	line-height: 24px;
	font-weight: 400;
	transition: background-color .3s, color .3s;
	text-align: center;
	margin-bottom: 15px; /*---*/
}

.reporting-period-select .period span {
	flex-basis: 100%;
	max-width: 100%;
}

.reporting-period-select .period span:first-of-type {
	margin-top: auto;
}

.reporting-period-select .period span:last-of-type {
	font-weight: 700;
	margin-bottom: auto;
}

.reporting-period-select .period:hover,
.reporting-period-select .period:focus {
	background-color: #1c5bae;
	color: #FFF;
}

@media (width <= 767px) {
	.reporting-period-info__wrapper {
	padding: 20px;
}

	.reporting-period-select .period {
	flex-basis: calc(50% - 10px);
	max-width: calc(50% - 10px);
	margin-bottom: 20px;
	}
}

@media (width <= 479px) {
	.reporting-period-info__wrapper {
	padding: 10px;
}

	.reporting-period-select .period {
	flex-basis: 100%;
	max-width: 350px;
	margin-bottom: 10px;
	margin-left: auto;
	margin-right: auto;
	}
}