.page-404__wrapper {
	background-color: #6b88e5;
	background-image: linear-gradient(132deg, #1c5bae 0%, #2278bb 100%);
	min-height: calc(100vh - 89px); /*---*/
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.page-404-info {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	flex-grow: 1;
	padding-top: 40px;
}

.block--404 {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	width: 100%;
}

.img-wrapper {
	flex-basis: auto;
	max-width: none;
	margin-bottom: 25px;
}

.img-wrapper img {
	max-width: 550px;
	height: auto;
}

.return-block {
	flex-basis: 100%;
	max-width: 100%;
	text-align: center;
}

.return-block h2,
.return-block a {
	color: white;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-weight: 300;
	line-height: normal;
}

.return-block h2 {
	font-size: 32px;
	margin-bottom: 15px;
}

.return-block a {
	font-size: 24px;
	display: inline-block;
	border-bottom: 1px solid white;
}

@media (width <= 767px) {
	.img-wrapper,
	.return-block {
		padding-left: 40px;
		padding-right: 40px;
	}

	.img-wrapper img {
		max-width: 100%;
		height: auto;
	}
}

@media (width <= 479px) {
	.return-block h2 {
		font-size: 23px;
		margin-bottom: 15px;
	}

	.return-block a {
		font-size: 22px;
	}
}