.privacy-policy-title ,
.privacy-policy-text__wrapper p {
	color: #353638;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	line-height: 26px;
}

.privacy-policy-title {
	font-size: 21px;
	font-weight: 700;
	text-align: center;
	margin-top: 0;
	margin-bottom: 30px;
}

.privacy-policy-text__wrapper p {
	font-size: 17px;
	font-weight: 400;
}

.privacy-policy-text__wrapper {
	background-color: #FFF;
	padding: 40px;
	max-width: 1340px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 20px;
	flex-grow: 1;
}

@media (width <= 479px) {
	.privacy-policy-text__wrapper {
		padding: 20px;
		margin-bottom: 0;
	}
}