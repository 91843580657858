.edit-page-footer {
	border-top: 1px solid #d3d3d4;
	padding-top: 20px;
	padding-bottom: 20px;
	padding-left: 35px;
	padding-right: 35px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 40px;
}

.edit-footer-copyright,
.edit-footer-menu {
	flex-basis: auto;
	max-width: none;
}

.edit-page-footer ul {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 0;
	padding-left: 0;
	list-style: none;
}

.edit-page-footer ul li {
	flex-basis: auto;
	max-width: none;
}

.edit-page-footer ul li a {
	display: inline-block;
	margin-left: 20px;
	color: #9a9a9b;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: normal;
}

.edit-footer-copyright p {
	color: #9a9a9b;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: normal;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 0;
}

@media (width <= 991px) {
	.edit-page-footer {
		border-top: 1px solid #d3d3d4;
		padding-top: 20px;
	}
}

@media (width <= 767px) {
	.edit-page-footer {
		justify-content: center;
		flex-wrap: wrap;
	}


	.edit-footer-copyright,
	.edit-footer-menu {
		flex-basis: 100%;
		max-width: 100%;
	}

	.edit-footer-copyright {
		margin-bottom: 20px;
	}

	.edit-footer-menu ul {
		justify-content: center;
		text-align: center;
	}

	.edit-page-footer ul li:first-of-type a {
		margin-left: 0;
	}
}

@media (width <= 479px) {
	.edit-page-footer ul {
		flex-wrap: wrap;
	}

	.edit-page-footer ul li {
		flex-basis: 100%;
		max-width: 100%;
		margin-bottom: 20px;
	}

	.edit-page-footer ul li a {
		margin-left: 0;
	}

	.edit-footer-copyright p {
		line-height: 1.6;
	}
}