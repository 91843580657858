.payment-for-gas-info {
	max-width: 540px;
	background-color: #FFF;
	border-radius: 10px;
	margin-left: auto;
	margin-right: auto;
	padding: 40px;
}

.payment-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.payment-item:first-of-type {
	margin-bottom: 10px;
}

.payment-item p,
.payment-item label {
	flex-basis: auto;
	max-width: none;
	margin-bottom: 0;
	color: #353638;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 17px;
	font-weight: 400;
	line-height: 26px;
}

.payment-gas-sum,
.payment-input  {
	flex-basis: 175px;
	max-width: 175px;
}

.payment-gas-sum {
	color: #1c5bae;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 17px;
	font-weight: 700;
	line-height: 26px;
}

.payment-input {
	display: flex;
	justify-content: flax-start;
	align-items: center;
	position: relative;
}

.payment-input span {
	color: #353638;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 17px;
	font-weight: 400;
	line-height: 26px;
}

.payment-for-gas-info .payment-note {
	display: inline-block;
	max-width: 175px;
	position: absolute;
	right: 0;
	top: 40px;
	color: #9a9a9b;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 14px;
	font-style: italic;
	line-height: 16px;
}

.payment-for-gas-info .payment-input input {
	max-width: 140px;
	margin-right: 15px;
	box-shadow: none;
	border: 1px solid rgba(164, 164, 164, 0.3);
	border-radius: 5px;
}

.payment-input span {
	flex-basis: auto;
	max-width: none;
}

.payment-button {
	margin-top: 70px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}

.pay-button,
.pay-icon {
	flex-basis: 100%;
	max-width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.pay-button a,
.pay-icon img {
	flex-basis: auto;
	max-width: none;
}

.payment-button a {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 10px;
	color: white;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 15px;
	font-weight: 500;
	width: 205px;
	height: 38px;
	background-color: #68b618;
	border-radius: 19px;
	transition: background-color .3s;
}

.payment-button a:hover,
.payment-button a:focus {
	color: white;
	background-color: #66af1b;
}

.pay-icon img {
	text-align: center;
}

@media (width <= 641px) {
	.payment-for-gas-info {
		border-radius: 0;
	}
}

@media (width <= 479px) {
	.payment-item {
		flex-wrap: wrap;
		max-width: 200px;
		margin-left: auto;
		margin-right: auto;
	}

	.payment-item p,
	.payment-item label {
		margin-bottom: 10px;
	}
}