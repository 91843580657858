.sign-sidebar__wrapper {
	flex-basis: 485px;
	max-width: 485px;
	padding-top: 35px;
	padding-bottom: 35px;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
}

.sign-sidebar--logo {
	margin-left: 35px;
	margin-bottom: 40px;
}

.sign-sidebar--forms {
	max-width: 315px;
	width: 315px;
	margin: auto;
}

.sign-sidebar--forms .form-group.adress-group { 	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.select-block {
	flex-basis: 150px;
	max-width: 150px;
}

.select-block:first-of-type {
	margin-right: 5px;
}

.select-block:last-of-type {
	margin-left: 5px;
}

.sign-sidebar--forms input,
.sign-sidebar--forms select {
	color: #9a9a9b;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 14px;
	border: 1px solid rgba(164, 164, 164, 0.3);
	border-radius: 5px;
}

.sign-sidebar--forms h2 {
	margin-bottom: 20px;
	text-align: center;
	color: #353638;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 21px;
}

.adress-group select {
	margin-right: 10px;
}

.adress-group input {
	margin-left: 10px;
}

.sign-sidebar--forms .form-group {
	margin-bottom: 20px;
}

.sign-buttons {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

.sign-buttons > a {
	width: 310px;
	height: 40px;
	border-radius: 19px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 20px;
}

.sign-buttons > a:last-of-type {
	margin-bottom: 25px;
}

.sign-up-button {
	background-color: #1c5bae;
	color: white;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 14px;
	font-weight: 500;
	text-transform: uppercase;
	border: 1px solid #1c5bae;
	transition: color .3s, background-color .3s;
}

.sign-up-button:hover,
.sign-up-button:focus {
	color: #1c5bae;
	background-color: #FFF;
}

.cancel-button {
	border: 1px solid #c2c3c5;
	color: #353638;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 14px;
	font-weight: 500;
	text-transform: uppercase;
	transition: color .3s, border-color .3s;
}

.cancel-button:hover,
.cancel-button:focus {
	color: #1c5bae;
	border-color: #1c5bae;
}

.sign-buttons p {
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 13px;
	color: #353638;
}

.sign-buttons p a {
	color: #1c5bae;
	font-weight: 500;
	margin-left: 5px;
}

.form-group--select,
.select-block:first-of-type {
	position: relative;
}

.form-group--select::before,
.select-block:first-of-type::before {
	position: absolute;
	content: "";
	top: 1px;
	right: 1px;
	width: 32px;
	height: 32px;
	border-radius: 5px;
	background-color: #FFF;
	pointer-events: none;
}

.form-group--select::after,
.select-block:first-of-type::after {
	position: absolute;
	content: "";
	top: 15px;
	right: 15px;
	border: 5px solid transparent;
	border-top: 5px solid #9a9a9b;
	pointer-events: none;
}

.form-group--select .profile-select::before {
	position: absolute;
	content: "";
	top: 1px;
	right: 161px;
	width: 32px;
	height: 32px;
	border-radius: 5px;
	background-color: #FFF;
	pointer-events: none;
}

.form-group--select .profile-select::after {
	position: absolute;
	content: "";
	top: 15px;
	right: 175px;
	border: 5px solid transparent;
	border-top: 5px solid #9a9a9b;
	pointer-events: none;
}


@media (width <= 991px) {
	.sign-sidebar--logo {
		position: absolute;
		left: 0;
		top: 20px;
		margin-left: 20px;
		margin-bottom: 0;
	}

	.sign-sidebar__wrapper {
		flex-basis: 100%;
		max-width: 100%;
		margin-top: 100px;
		padding-top: 25px;
		border-top: 1px solid #e3e3e3;
	}
}

@media (width <= 641px) {
	.edit-profile-info .form-group--select::before {
		top: 31px;
	}


	.edit-profile-info .form-group--select::after {
		top: 45px;
	} 

	.form-group--select .profile-select::before {
		top: 31px;
		left: calc(50% - 38px);
	}

	.form-group--select .profile-select::after {
		top: 45px;
		left: calc(50% - 30px);
	}
}

@media (width <= 479px) {
	.sign-content--footer {
		border-color: rgba(229, 229, 230, .1);
		padding-left: 0;
		padding-right: 0;
		width: calc(100% - 40px);
		margin-right: auto;
		margin-left: auto;
	}

	.sign-sidebar--logo {
		position: absolute;
		top: 35px;
		left: 50%;
		transform: translateX(-50%);
		margin-left: 0;
	}

	.sign-sidebar__wrapper {
		border-top: none;
		margin-top: 165px;
	}

	.sign-sidebar--forms {
		max-width: calc(100% - 40px);
	}

	.sign-buttons > a {
		width: 100%;
		max-width: 100%;
		border-radius: 22px;
	}

	.sign-buttons p {
		font-size: 16px;
	}

	.sign-sidebar--forms input,
	.sign-sidebar--forms select {
		font-size: 16px;
	}
}