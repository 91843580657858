.header-dropdown-menu {
	position: relative;
	z-index: 10000;
}

.header-dropdown-menu button {
	display: flex;
	justify-content: center;
	align-items: center;
	outline-color: transparent;
	border: none;
	box-shadow: none;
}

.header-dropdown-menu button p {
	margin-bottom: 0;
	margin-left: 20px;
	margin-right: 20px;
	color: #9a9a9b;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 18px;
	font-weight: 400;
	line-height: 24px;
}

.header-dropdown-menu .btn-default {
	background-color: transparent;
}

.btn-default:focus, 
.btn-default.focus,
.btn-default:hover {
	color: #9a9a9b;
	background-color: transparent;
	border-color: transparent;
	outline-color: transparent;
	box-shadow: none;
}

.btn-default:active:hover, 
.btn-default.active:hover, 
.open > .dropdown-toggle.btn-default:hover, 
.btn-default:active:focus, 
.btn-default.active:focus, 
.open > .dropdown-toggle.btn-default:focus, 
.btn-default:active.focus, 
.btn-default.active.focus, 
.open > .dropdown-toggle.btn-default.focus {
	color: #9a9a9b;
	background-color: transparent;
	border-color: transparent;
	outline-color: transparent;
	box-shadow: none;
}

.btn-default:active, 
.btn-default.active, 
.open > .dropdown-toggle.btn-default {
	color: #9a9a9b;
	background-color: transparent;
	border-color: transparent;
	outline-color: transparent;
	box-shadow: none;
}

.header-dropdown-menu ul li a {
	color: #353638;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 1.2;
	padding-top: 15px;
	padding-bottom: 15px;
}

.header-dropdown-menu .personal-exit {
	color: #1c5bae;
}

.dropdown-menu > li > a:hover, 
.dropdown-menu > li > a:focus {
	color: #353638;
	background-color: transparent;
}

.dropdown-menu > li > .personal-exit:hover, 
.dropdown-menu > li > .personal-exit:focus {
	color: #1c5bae;
	background-color: transparent;
}

.header-dropdown-menu .dropdown-menu {
	border-radius: 10px;
	box-shadow: none;
	border: none;
	background-color: white;
	padding: 0;
	left: auto;
	right: 45px;
	min-width: 230px;
}

.header-dropdown-menu .dropdown-menu .divider {
	margin: 0;
}

.dropdown-arrow {
	align-self: stretch;
}

@media (width <= 641px) {
	.header-dropdown-menu .dropdown-menu {
		left: 50%;
		transform: translateX(-50%);
		min-width: 230px;
	}
}

@media (width <= 479px) {
.header-dropdown-menu button p {
	white-space: pre-wrap;
	margin-left: 10px;
  margin-right: 10px;
}
}

@media (width <= 320px) {
	.dropdown-arrow {
	align-self: center;
}
}