.page-text__wrapper {
	background-color: #f0f0f0;
	min-height: calc(100vh - 90px);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.page-text-info {
	padding-top: 60px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-left: 35px;
	margin-right: 35px;
	width: calc(100% - 70px);
	flex-grow: 1;
}

@media (width <= 767px) {
	.page-text-info {
		margin-left: 20px;
		margin-right: 20px;
		width: calc(100% - 40px);
	}
}

@media (width <= 479px) {
	.page-text-info {
		margin-left: 10px;
		margin-right: 10px;
		width: calc(100% - 20px);
		padding-top: 35px;
	}

}