.general-footer {
	border-top: 1px solid #d3d3d4;
	padding-top: 20px;
	padding-bottom: 20px;
	padding-left: 35px;
	padding-right: 35px;
	margin-top: auto;
}

.general-footer ul {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 0;
	padding-left: 0;
	list-style: none;
}

.general-footer ul li {
	flex-basis: auto;
	max-width: none;
}

.general-footer ul li a {
	display: inline-block;
	margin-left: 20px;
	color: #9a9a9b;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: normal;
}

.general-footer-copyright {
	display: none;
}

.general-footer-copyright p {
	color: #353638;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	max-width: 235px;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 0;
}

@media (width <= 991px) {
	.general-footer {
	border-top: 1px solid #d3d3d4;
	padding-top: 20px;
	padding-bottom: 10px;
	padding-left: 0;
	padding-right: 0;
	margin-left: 30px;
	margin-right: 30px;
	width: calc(100% - 60px);
}

	.general-footer nav {
		display: none;
	}

	.general-footer-copyright {
		display: block;
	}
}

@media (width <= 767px) {
	.general-footer {
		margin-right: 20px;
		margin-left: 20px;
		width: calc(100% - 40px);
	}
}

@media (width <= 479px) {
	.general-footer {
		margin-right: 0;
		margin-left: 0;
		width: 100%;
		max-width: 100%;
		min-height: 73px;
		height: 73px;
	}
}