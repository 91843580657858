::-webkit-scrollbar-button {
	background-image: url('');
	background-repeat: no-repeat;
	width: 3px;
	height: 0px;
}

::-webkit-scrollbar-track {
	background-color: #ecedee;
}

::-webkit-scrollbar-thumb {
	-webkit-border-radius: 0px;
	border-radius: 0px;
	background-color: rgba(32, 110, 182, .7);
}

::-webkit-scrollbar-thumb:hover {
	background-color: rgba(32, 110, 182, .7);
}

::-webkit-resizer {
	background-image: url('');
	background-repeat: no-repeat;
	width: 2px;
	height: 0px;
}

::-webkit-scrollbar {
	width: 4px;
	height: 10px;
}