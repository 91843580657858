.remodal-manual {
	max-width: 700px;
	background-color: white;
	border-radius: 6px;
	padding-left: 50px;
}

.remodal-manual .remodal-close::before {
	color: black;
}

.remodal-manual .manual-info {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-wrap: wrap;
	max-width: 590px;
}

.remodal-manual .manual-info h2,
.remodal-manual .manual-info ol,
.remodal-manual .manual-info p {
	flex-basis: 100%;
	max-width: 100%;
}

.remodal-manual .manual-info h2 {
	color: #353638;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 21px;
	font-weight: 500;
	margin-top: 10px;
	margin-bottom: 30px;
}

.remodal-manual .manual-info ol {
	padding-left: 15px;
	margin-bottom: 0;
}

.remodal-manual .manual-info ol li,
.remodal-manual .manual-info p {
	text-align: left;
	color: #353638;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 17px;
	line-height: 26px;
}

.remodal-manual .manual-info ol li {
	margin-bottom: 20px;
}

.remodal-manual .manual-info p span {
	padding-right: 5px;
	font-weight: 700;
}

.remodal-overlay {
  background: rgba(43, 46, 56, 0.15);
}

.remodal-close::before {
	position: absolute;
	top: 10px;
	left: -10px;
	display: block;
	width: 35px;
	content: url('../img/cross.png');
	text-align: center;
}

@media (width <= 479px) {
	.remodal-manual {
		padding-left: 20px;
	}
}