.account-current-balance {
	display: none;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.account-current-balance .current-balance {
	color: #1c5bae;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 48px;
	font-weight: 500;
	line-height: 24px;
	margin-top: 5px;
}

.account-current-balance .current-balance-name {
	margin-bottom: 15px;	
}

.account-current-balance .current-balance-name p {
	color: #353638;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 20px;
	font-weight: 400;
	line-height: 24px;
	margin-right: 0;
}

.account-current-balance .payment {
	width: 251px;
	height: 38px;
	color: white;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 15px;
	font-weight: 500;
	background-color: #1c5bae;
	border-radius: 19px;
	border: 1px solid #1c5bae;
	box-shadow: none;
	margin-bottom: 30px;
	margin-top: 0;
	transition: background-color .3s;
}

.account-current-balance .payment:hover, 
.account-current-balance .payment:hover {
	background-color: #184d92;
}

@media (width <= 991px) {
	.account-current-balance {
		display: flex;
		margin-top: 45px;
	}
}