@media (width <= 479px) {
	@supports (-ms-ime-align:auto)  {
		
		.sign-sidebar--logo {
			right: auto;
			left: calc(50% - 140px);
			transform: translateX(0px);
		}

		.manual {
			width: 310px;
			left: calc(50% - 155px);
			transform: translateX(0px);
		}
	}
}