.fogot-password-info {
	max-width: 400px;
	margin-right: auto;
	margin-left: auto;
}

.fogot-password-info input {
	margin-bottom: 30px;
}

.fogot-password-info .sign-buttons > a {
	width: 400px;
}

.fogot-password-info h2 {
	color: #353638;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 21px;
	font-weight: 500;
	margin-top: 10px;
	margin-bottom: 30px;
}

.fogot-password-info p {
	text-align: center;
	color: #353638;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 17px;
	line-height: 26px;
	margin-bottom: 30px;
}

.fogot-password-info.password-sent-text {
	max-width: 500px;
}

.fogot-password-info.password-sent-text p {
	margin-bottom: 0;
}