.general-form {
	display: flex;
	padding: 30px;
	margin-top: 35px;
	margin-right: 35px;
	margin-bottom: auto; /*---*/
	margin-left: 35px;
	background-color: #FFF;
	width: calc(100% - 70px);
}

.general-form .form-group {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex-grow: 1;
	padding-right: 10px;
	margin-bottom: 0;
	/* width: 300px; */
	max-width: 300px; /*---*/
}

.general-form .form-group label {
	padding-bottom: 10px;
	color: #353638;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 15px;
	font-weight: 400;
	margin-bottom: auto;
}

.general-form .form-group input {
	color: #9a9a9b;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 14px;
	font-weight: 400;
	background-color: white;
	cursor: default;
}

select,
input,
textarea {
	-webkit-appearance: none;
	box-shadow: none;
}

.form-control {
	box-shadow: none;
}

@media (width <= 1199px) {
	.general-form .form-group:nth-of-type(4) input,
	.general-form .form-group:nth-of-type(5) input {
		max-width: 100px;
	}

	.general-form .form-group {
		padding-right: 15px;
	}
}

@media (width <= 991px) {
	.general-form {
		flex-direction: column;
		padding-left: calc(50% - 300px);
		padding-right: calc(50% - 300px);
		margin-top: 45px;
	}

	.general-form .form-group {
		flex-direction: row;
		max-width: 520px;
		/* width: 520px;  */
		margin-bottom: 10px;
		padding-right: 0;
	}

	.general-form label {
		margin-right: 30px;
	} 

	.general-form .form-group:nth-of-type(4) input,
	.general-form .form-group:nth-of-type(5) input {
		max-width: 310px;
	}

	.general-form .form-group input {
		max-width: 310px;
	}
}

@media (width <= 767px) {
	.general-form {
		margin-top: 30px;
		margin-right: 20px;
		margin-left: 20px;
		width: calc(100% - 40px);
	}
}

@media (width <= 641px) {
	.general-form {
		padding-left: 20px;
		padding-right: 20px;
	}

	.general-form .form-group:nth-of-type(4) input,
	.general-form .form-group:nth-of-type(5) input {
		max-width: calc(100% - 180px);
	}

	.general-form .form-group input {
		max-width: calc(100% - 180px);
	}

	.general-form label {
		margin-right: 20px;
	} 
}

@media (width <= 479px) {
	.general-form {
		padding: 20px;
		margin-top: 35px;
		margin-right: 10px;
		margin-bottom: 20px;
		margin-left: 10px;
		justify-content: space-between;
		width: calc(100% - 20px);
	}

	.general-form .form-group:nth-of-type(4) input,
	.general-form .form-group:nth-of-type(5) input {
		max-width: calc(100% - 120px);
		min-width: 110px;
	}

	.general-form .form-group input {
		max-width: calc(100% - 120px);
		min-width: 110px;
	}

	.general-form .form-group label {
		margin-right: 10px;
		padding-bottom: 0px;
		line-height: 1;
		margin-bottom: 0;
	} 
}