.general__wrapper {
	display: flex;
	flex-direction: column;
	height: 100vh;
	overflow-y: hidden;
}

.general-sidebar__wrapper {
	flex-basis: 300px;
	max-width: 300px;
	width: 300px; /*---*/
	background-color: #1c5bae;
	padding-top: 60px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	height: 100%;
}

.sidebar-info,
.sidebar-footer {
	width: 100%;
}

.current-balance {
	color: white;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 34px;
	font-weight: 300;
	line-height: normal;
	margin-bottom: 5px;
	text-align: center;
}

.current-balance-name {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 10px;
	/* margin-bottom: 10px; */
	padding-bottom: 15px;
	position: relative;
}

.current-balance-name:hover {
	cursor: pointer;
}

.balance-name {
	display: flex;
	align-items: center;
	/* padding-top: 15px; */
	/* padding-bottom: 15px; */
	/* margin-top: 15px; */
}

.balance-name svg {
	/* padding-bottom: 15px; */
}

.icon-info-title__wrapper {
	position: absolute;
	z-index: 1;
	top: 20px;
	left: 0;
	width: 100%;
	height: auto;
	padding-top: 15px;
	visibility: hidden;
}

.icon-info-title {
	background-color: #FFF;
	padding-top: 30px;
	padding-left: 30px;
	padding-right: 30px;
	padding-bottom: 25px;
	border: 1px solid #e8e8e8;
	box-shadow: 0 0 5px rgba(255, 255, 190, 0.36);
}

.balance-name:hover .icon-info-title__wrapper, 
.balance-name:focus .icon-info-title__wrapper {
	animation: fade-in .3s linear 1 forwards, move-up .3s linear forwards 1;
}


.icon-info-title::before {
	border-bottom: 7px solid white;
	border-right: 7px solid transparent;
	border-left: 7px solid transparent;
	top: 10px;
	content: '';
	display: block;
	left: 50%;
	margin-left: -7px;
	position: absolute;
}

.icon-info-title h3 {
	text-align: center;
	margin-top: 0;
	color: #353638;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 21px;
	font-weight: 700;
	line-height: 24px;
	margin-bottom: 15px;
}

.icon-info-title ul {
	padding-left: 25px;
	margin-bottom: 0;
	list-style: none;
}

.icon-info-title ul li {
	color: #1c5bae;
	position: relative;
	margin-bottom: 10px;
}

.icon-info-title ul li::before {
	position: absolute;
	content: "";
	width: 5px;
	height: 5px;
	background-color: #1C5BAE;
	border-radius: 50%;
	top: 10px;
	left: -15px;
}

.icon-info-title .title-date {
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 17px;
	line-height: 26px;
	color: #353638;
	font-weight: 400;
}

.icon-info-title .title-sum {
	color: #1c5bae;
	font-weight: 700;
}

@keyframes fade-in {
	from { opacity: 0;visibility: hidden; }
	to { opacity: 1;visibility: visible; }
}

@keyframes move-up {
	from { top: 5px; }
	to { top: 15px; }
}

.current-balance-name p {
	color: #d9deef;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 17px;
	font-weight: 400;
	line-height: normal;
	text-align: center;
	margin-bottom: 0;
	margin-right: 10px;
	cursor: default; /*---*/
}

.payment {
	display: flex;
	justify-content: center;
	align-items: center;
	color: #1c5bae;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 15px;
	font-weight: 500;
	width: 205px;
	height: 38px;
	background-color: white;
	border-radius: 19px;
	box-shadow: 0 7px 10px rgba(48, 48, 49, 0.11);
	border: 1px solid white;
	transition: background-color .3s, border-color .3s;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 40px;
	margin-top: 15px;
}

.payment:hover,
.payment:focus {
	background-color: whitesmoke;
	border-color: whitesmoke;
}

.disable {
	border-color: white;
	background-color: transparent;
	color: #FFF;
}

.sidebar-menu {
	padding-left: 5px;
	list-style: none;
	display: flex;
	flex-direction: column;
	
}

.sidebar-menu li {
	/* margin-bottom: 20px; */
}

.sidebar-menu li a {
	color: #d9deef;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 18px;
	font-weight: 500;
	line-height: 24px;
	transition: color .3s;
}

.sidebar-menu li:hover a,
.sidebar-menu li.active a {
	color: white;
}

.sidebar-footer {
	max-width: 240px;
	margin-left: auto;
	margin-right: auto;
	padding-bottom: 20px;
	margin-top: auto;
}

.sidebar-footer p {
	text-align: center;
	color: #d9deef;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 0;
}

@media (width >= 992px) {
	.sidebar-menu:last-of-type {
		display: none;
	}
}

@media (width <= 991px) {
	.general-sidebar__wrapper {
		height: auto;
		position: absolute;
		top: 83px;
		left: 0;
		right: 0;
		width: 100%;
		flex-basis: 100%;
		max-width: 100%;
		padding-top: 0;
		padding-bottom: 0;
		z-index: 2;
		overflow-y: auto;
	}

	.sidebar-info,
	.sidebar-footer
	{
		display: none;
	}

	.sidebar-menu {
		padding-left: 0;
		margin-top: 0;
		margin-bottom: 0;
		padding-bottom: 10px;
		border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	}

	.sidebar-menu:last-of-type {
		border-bottom: none;
		padding-top: 10px;
	}

	.sidebar-menu li {
		margin-bottom: 0;
	}

	.sidebar-menu li a {
		line-height: 1;
	}
}

@media (width <= 767px) {
	.general__wrapper {
		height: auto;
	}
}

@media (width <= 641px) {
	.general-sidebar__wrapper {
		top: 126px;
	}
}

@media (width <= 479px) {
	.general-sidebar__wrapper {
		top: 146px;
	}
}