/* variables */
:root {
	--red: #d92f27;
	--darkBrown: #262525;
	--brown: #4c3e30;
	--lightBrown: #6b5b4b;
	--darkGreen: #5da53e;
	--green: #7dc160;
	--respMenuBG: #e8dfd6;
}

/* properties */
:root {
	
	--opStart {
		will-change: opacity;
		opacity: 1;
		transition: opacity .3s;
	}
	
	--opEnd {
		opacity: .7;
		transition: opacity .3s;
	}

	/* fonts */
	
	--r3i {
		font-family: 'Roboto', sans-serif;
		font-weight: 300;
		font-style: italic;
	}

	--r4 {
		font-family: 'Roboto', sans-serif;
		font-weight: 400;
		font-style: normal;
	}

	--r5 {
		font-family: 'Roboto', sans-serif;
		font-weight: 500;
		font-style: normal;
	}

	--r7 {
		font-family: 'Roboto', sans-serif;
		font-weight: 700;
		font-style: normal;
	}
}