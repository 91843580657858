.report-info__wrapper {
	margin: 35px;
	width: calc(100% - 70px);
	max-width: calc(100% - 70px);
	background-color: #FFF;
	padding-top: 20px;
	padding-left: 30px;
	padding-right: 30px;
	padding-bottom: 15px;
}

.report-info__wrapper h2 {
	margin-top: 0;
	line-height: 34px;
	color: #353638;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 24px;
	font-weight: 500;
	text-align: center;
	max-width: 375px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 20px;
}

.report-data {
	display: flex;
	align-items: stretch;
	width: 100%;
	max-width: 995px;
	margin-left: auto;
	margin-right: auto;
}

.report-data--item {
	flex-basis: 50%;
	max-width: 50%;
	padding: 20px;
	border: 1px solid #e4e4e4;
	display: flex;
	align-items: center;
}

.report-data--item:last-of-type {
	border-left: none;
}

.report-data-img {
	margin-right: 20px;
	flex-basis: auto;
	max-width: none;
}

.report-data-text {
	flex-basis: auto;
	max-width: none;
} 

.report-data-text p,
.report-data-text span {
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 17px;
	font-weight: 400;
	line-height: normal;
}

.report-data-text p {
	color: #9a9a9b;
	margin-bottom: 5px;
}

.report-data-text span {
	color: #353638;
}

.report-notice {
	margin-bottom: 20px;
}

.report-notice,
.report-table {
	max-width: 995px;
	margin-left: auto;
	margin-right: auto;
}

.report-notice h3,
.report-notice p {
	color: #353638;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 17px;
	line-height: 26px;
	font-weight: 400;
	text-align: center;
}

.report-notice span {
	font-weight: 700;
	margin-left: 5px;
	margin-right: 5px;
}

.report-table table {
	width: 100%;
	margin-bottom: 20px;
	margin-top: 10px;
	min-width: 870px;
}

.report-table table th,
.report-table table td {
	padding: 10px;
	width: 325px;
	color: #353638;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 17px;
	line-height: normal;
}

.report-table table th {
	font-weight: 700;
	vertical-align: baseline;
}

.report-table table .tr-not-bold th {
	font-weight: 400;
}

.report-table table td {
	font-weight: 400;
}

.report-table table td:first-of-type {
	font-weight: 700;
}

.table-bordered > thead > tr > th, 
.table-bordered > tbody > tr > th, 
.table-bordered > tfoot > tr > th, 
.table-bordered > thead > tr > td, 
.table-bordered > tbody > tr > td, 
.table-bordered > tfoot > tr > td {
	border: 1px solid #e4e4e4;
}

.report-table--item p {
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	line-height: 26px;
	color: #353638;
	font-size: 17px;
	font-weight: 400;
}

.report-table--item p span {
	display: inline-block;
	line-height: 1.2;
	border-bottom: 1px solid black;
	margin-left: 10px;
}

.span-bold {
	display: inline-block;
	margin-left: 5px;
	margin-right: 5px;
	color: #353638;
	font-weight: 700;
}

.span-note {
	display: inline-block;
	color: #9a9a9b;
	font-size: 16px;
	font-weight: 400;
	padding-bottom: 5px;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
}

.report-table a {
	display: inline-block;
	margin-left: 5px;
	border-bottom: 1px solid #1c5bae;
	line-height: normal;
	color: #353638;
	padding-bottom: 2px;
	transition: color .3s;
}

.report-table a:hover,
.report-table a:focus {
	color: #1c5bae;
}

.report-table .ul-title {
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	color: #353638;
	font-size: 21px;
	font-weight: 700;
	margin-bottom: 20px;
}

.report-table ul {
	padding-left: 20px;
	list-style: none;
}

.report-table ul li {
	margin-bottom: 10px;
	color: #353638;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 17px;
	line-height: 26px;
	font-weight: 400;
	position: relative;
}

.report-table ul li::before {
	position: absolute;
	content: "";
	width: 6px;
	height: 6px;
	background-color: #1c5bae;
	border-radius: 50%;
	top: 50%;
	transform: translateY(-50%);
	left: -20px;
}

.report-sign {
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 17px;
	line-height: 26px;
	font-weight: 400;
	font-style: italic;
	text-align: right;
}

.report-table__wrapper {
	overflow-x: auto;
}

@media (width <= 767px) {
	.report-data {
		flex-wrap: wrap;
	}

	.report-info__wrapper {
		margin-top: 30px;
		margin-left: 20px;
		margin-right: 20px;
		margin-bottom: 20px;
		max-width: calc(100% - 40px);
	}

	.report-data--item {
		flex-basis: 100%;
		max-width: 100%;
		margin-bottom: 20px;
	}

	.report-data--item:last-of-type {
		border: 1px solid #e4e4e4;
	}
}

@media (width <= 479px) {
	.report-info__wrapper {
		margin-left: 10px;
		margin-right: 10px;
		margin-bottom: 20px;
		max-width: calc(100% - 20px);
		padding-top: 20px;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 15px;
	}
}