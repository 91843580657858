.sign-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	width: 100%;
	height: 100vh;
	overflow-y: hidden;
}

@media (width <= 991px) {
	.sign-wrapper {
		flex-wrap: wrap;
		justify-content: center;
		overflow-y: inherit;
	}
}