.table-search {
	display: flex;
	margin-right: 35px;
	margin-bottom: 15px;
	margin-left: 35px;
	margin-top: 30px;
}

.table-search label {
	color: #353638;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 15px;
	font-weight: 400;
	line-height: normal;
	margin-right: 15px;
	margin-bottom: 0;
}

.table-search select {
	color: #9a9a9b;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: normal;
	width: 85px;
	height: 33px;
	background-color: white;
	border: 1px solid #e4e4e4;
	border-radius: 5px;
	margin-right: 10px;
	padding-left: 5px;
}

.table-search-button a {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 123px;
	height: 32px;
	color: white;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 15px;
	font-weight: 500;
	line-height: normal;
	background-color: #1c5bae;
	border-radius: 5px;
	border: 1px solid #1c5bae;
	transition: background-color .3s;
}

.table-search-button a:hover, 
.table-search-button a:hover {
	background-color: #184d92;
}

@media (width <= 479px) {
	.table-search {
		margin-left: 10px;
		margin-right: 10px;
	}
}