
.btn__wrapper {
	display: none;
}

.nav > li > a:hover, 
.nav > li > a:focus {
	background-color: transparent;
}

.navbar-nav > li > a {
	padding-bottom: 10px;
}

@media (width <= 991px) {
	.btn__wrapper {
		display: block;
	}

	.close-button {
		display: block;
		width: 100%;
		cursor: pointer;
		opacity: 1;
		will-change: opacity;
		transition: opacity .3s, transform .3s;
		padding-top: 20px;
		padding-bottom: 20px;
		margin: 0 auto;
		position: static;
		float: none;
	}

	.navbar-toggle .icon-bar {
		background-color: #FFF;
		width: 28px;
		margin-left: auto;
		margin-right: auto;
		transition: transform .3s;
		transform-origin: center;
	}

	.navbar-toggle .icon-bar + .icon-bar {
		margin-top: 6px;
	}

	.navbar-toggle:not(.collapsed) .icon-bar:nth-of-type(1) {
		transform: translateY(8px) rotateZ(45deg);
	}

	.navbar-toggle:not(.collapsed) .icon-bar:nth-of-type(2) {

		transform: rotateY(90deg);
	}

	.navbar-toggle:not(.collapsed) .icon-bar:nth-of-type(3) {
		transform: translateY(-8px) rotateZ(-45deg);
	}

	.navbar-toggle .icon-bar:nth-of-type(1),
	.navbar-toggle .icon-bar:nth-of-type(2),
	.navbar-toggle .icon-bar:nth-of-type(3)	 {
		transform: rotate(0deg);
	}

	.sidebar-menu {
		box-shadow: none;
	}

	.sidebar-menu ul {
		flex-direction: column;
		align-items: center;
	}

	.sidebar-menu li {
		width: 100%;
		text-align: center;
	}

	.sidebar-menu  li:first-child a {
		padding-left: 15px;
	}

	.sidebar-menu li:last-child a {
		padding-right: 15px;
	}

	.nav > li > a:hover, 
	.nav > li > a:focus {
		background-color: transparent;
	}

	#headerNav {
		box-shadow: none;
		overflow-y: auto;
	}

	.navbar-nav > li > a {
		padding-bottom: 15px;
		padding-top: 15px;
		line-height: normal;
	}
}

@media (width >= 992px) {
	#headerNav.collapse {
		display: block;
		box-shadow: none;
	}

	#headerNav.collapse ul {
		margin: 0;
	}

	#headerNav.collapse ul a {
		padding-top: 15px;
	}
}