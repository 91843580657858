.header__wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding-top: 20px;
	padding-bottom: 20px;
	background-color: white;
	box-shadow: 0 3px 7px rgba(0, 0, 0, 0.07);
}

.header-logo,
.header-contact-info,
.header-dropdown-menu {
	flex-basis: auto;
	max-width: none;
}

.header-logo {
	padding-left: 25px;
}

.header-dropdown-menu {
	padding-right: 35px;
}

.header-contact-info p {
	color: #353638;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 18px;
	line-height: 24px;
	font-weight: 400;
	margin-bottom: 0;
}

.header-contact-info span {
	font-weight: 300;
	display: inline-block;
	margin-left: 5px;
}

.header-contact-info .numbers {
	font-weight: 700;
}

@media (width <= 991px) {
	.header-contact-info {
		display: none;
	}
} 

@media (width <= 641px) {
	.header-dropdown-menu {
		padding-right: 0;
	}
}

@media (width <= 641px) {
	.header__wrapper {
	flex-wrap: wrap;
	justify-content: center;
	}

	.header-logo {
		padding-left: 0;
		margin-bottom: 10px;
		flex-basis: 100%;
		text-align: center;
	}

	.header-dropdown-menu {
		padding-right: 0;
	}

	.header-dropdown-menu.dropdown {
		padding-right: 0;
	}
}

@media (width <= 479px) {
	.header__wrapper {
		padding-bottom: 30px;
	}

	.header-logo {
		margin-bottom: 20px;
	}
}