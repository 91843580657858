.checkbox-remember input {
	width: 18px;
	height: 18px;
	background-color: #1c5bae;
	border-radius: 3px;
	margin-top: 0;
	position: relative;
}

.checkbox-remember input:focus {
	outline-color: transparent;
	box-shadow: none;
}

.checkbox-remember {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	position: relative;
}

.checkbox-remember input::before {
	position: absolute;
	content: "";
	top: -1px;
	left: -1px;
	width: 18px;
	height: 18px;
	background-color: white;
	border-radius: 3px;
	border: 1px solid rgba(164, 164, 164, .7);
}

.checkbox-remember img {
	position: absolute;
	left: 3px;
	top: 5px;
	width: 12px;
	height: 9px;
	pointer-events: none;
}

.tick-hide {
	display: none;	
}

.tick-visible {
	display: block;
}

.sign-sidebar--forms input[type="checkbox"] {
	margin-top: 1px;
	margin-right: 10px;
}

.sign-sidebar--forms span,
.sign-checkbox a {
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 14px;
}

.sign-sidebar--forms span {
	color: #9a9a9b;
}

.sign-checkbox a {
	color: #1c5bae;
}

.sign-checkbox {
	display: flex;
	justify-content: space-between;
}

.sign-in-p p {
	margin-bottom: 20px;
}

/* .form-group.has-error {
	display: flex;
	flex-direction: column;
}
 */
.form-group label {
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 12px;
}


@media (width <= 479px) {
	.checkbox-remember a, 
	.checkbox-remember span {
	font-size: 16px;
	}
}