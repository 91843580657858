.invoice-main {
	margin-left: auto;
	margin-right: auto;
	border-bottom: 1px dotted black;
	margin-bottom: 40px;
}

.invoice-main p {
	font-size: 13px;
	margin-bottom: 0;
}

.invoice-main span {
	display: inline-block;
	font-size: 13px;
}

.td-invoice-left {
	width: 390px;
	padding-top: 15px;
	padding-right: 15px;
	padding-bottom: 20px;
	padding-left: 10px;
	border-right: 1px dotted black;
}

.table-left-header {
	width: 375px;
	border: 1px solid black;
	margin-bottom: 20px;
}

.info-organization {
	width: 55%;
	border-right: 1px solid black;
	padding: 5px;
}

.info-organization h3 {
	margin-top: 0;
	margin-bottom: 0;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: bold;
	text-align: center;
}

.info-organization p {
	margin-bottom: 0;
	font-size: 13px;
}

.info-payer {
	width: 45%;
	padding-top: 5px;
	padding-right: 5px;
	padding-bottom: 5px;
	vertical-align: top;
}

.info-payer p {
	margin-bottom: 5px;
	font-weight: bold;
	line-height: 1.2;
}

.info-payer span {
	font-size: 13px;
}

.details-name,
.dash-name {
	display: inline-block;
	margin-left: 5px;
}

.details-text {
	display: inline-block;
	margin-left: 5px;
}

.dash {
	display: inline-block;
	margin-left: 5px;
	border-bottom: 1px solid black;
	font-weight: normal;
}

.details-name {
	font-weight: bold;
}

.details-text {
	font-weight: normal;
}

.name > .details-name{
	text-transform: uppercase;
}

.info-payer .name > .dash-name {
	font-weight: normal;
	text-transform: uppercase;
}

.table-left-content {
	width: 375px;
	margin-bottom: 20px;
}

.table-left-content h2 {
	margin-top: 0;
	margin-bottom: 10px;
	font-size: 18px;
	text-align: center;
	font-weight: bold;
}

.left-content--top-table,
.left-content--bottom-table {
	width: 100%;
}

.left-content--top-table h3,
.left-content--bottom-table h3 {
	margin-top: 0;
	margin-bottom: 0;
	font-weight: bold;
	font-size: 14px;
}

.left-content--top-table {
	margin-bottom: 40px;
}

.left-content--bottom-table h3 {
	text-align: center;
}

.left-content--top-table tr td,
.left-content--bottom-table tr td {
	padding: 5px;
}

.left-content--top-table tr:not(:first-of-type) td,
.left-content--bottom-table tr:not(:first-of-type) td {
	border: 1px solid black;
}

.value {
	width: 60px;
}

.right-content--top-table,
.right-content--bottom-table {
	width: 100%;
}

.right-content--top-table h3,
.right-content--bottom-table h3 {
	margin-top: 0;
	margin-bottom: 0;
	font-weight: bold;
	font-size: 14px;
}

.right-content--top-table {
	margin-bottom: 20px;
}

.right-content--bottom-table h3 {
	text-align: center;
}

.right-content--top-table tr td,
.right-content--bottom-table tr td {
	padding: 5px;
}

.right-content--top-table tr:not(:first-of-type) td,
.right-content--bottom-table tr:not(:first-of-type) td {
	border: 1px solid black;
}

.tr-left-content {
	width: 100%;
}

.tr-left-content .td-left {
	padding-right: 10px;
	vertical-align: top;
}

.tr-left-content .td-right {
	padding-left: 10px;
	vertical-align: top;
}

.table-left-footer h2 {
	font-weight: bold;
	font-size: 16px;
	text-align: center;
	margin-top: 0;
	margin-bottom: 5px;
}

.table-left-footer p {
	margin-bottom: 5px;
	line-height: normal;
}

.table-left-footer p:first-of-type {
	text-align: center;
	margin-bottom: 0;
}

.table-left-footer a {
	display: inline-block;
	margin-left: 5px;
	color: black;
	text-decoration: underline;
}

.td-invoice-right {
	vertical-align: top;
	width: 100%;
	padding-left: 15px;
	padding-top: 15px;
	padding-right: 10px;
}

.table-right-header {
	border: 1px solid black;
	margin-bottom: 20px;
}

.table-right-header .info-organization {
	padding: 5px;
}

.table-right-header .info-organization h3 {
	font-size: 14px;
}

.table-right-notice {
margin-bottom: 20px;
}

.notice-title,
.notice-title span {
	margin-top: 0;
	font-size: 15px;
	text-align: center;
	font-weight: bold;
}

.notice-title {
	margin-bottom: 10px;
}

.table-right-payment tr td {
	border: 1px solid black;
	padding: 5px;
}

.table-right-payment {
	padding: 5px;
	margin-bottom: 10px;
}

.table-right-sum {
	margin-bottom: 40px;
}

.table-right-sum tr:not(:first-of-type) td {
	border: 1px solid black;
}

.table-right-sum tr td {
	padding: 5px;
}

.table-right-payment tr td:last-of-type,
.table-right-sum tr td:last-of-type {
	width: 50px;
}

.table-right-sum h3 {
	margin-top: 0;
	margin-bottom: 0;
	font-size: 13px;
	font-weight: bold;
}

.table-right-sum .table-sum-title {
	text-align: center;
	font-size: 15px;
}

.table-right-sign td {
	padding: 5px;
}

.table-right-sign p:first-of-type {
	margin-bottom: 10px;
}

.table-right-sign span:first-of-type {
	display: inline-block;
	margin-right: 0;
}

.table-right-sign .dash {
	width: 100px;
	margin-left: 0;
	margin-right: 3px;
}