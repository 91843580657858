.account-info {
	display: flex;
	flex-wrap: wrap;
	width: calc(100% - 70px);
	margin-left: 35px;
	margin-right: 35px;
	margin-bottom: 30px;
	padding-top: 10px;
	padding-left: 30px;
	padding-right: 30px;
	padding-bottom: 30px;
	background-color: #FFF;
}

.account-info-number {
	flex-basis: 100%;
	max-width: 100%;
	display: flex;
	justify-content: flex-start;
	padding-top: 20px;
	padding-bottom: 20px;
}

.account-info--item {
	flex-basis: 50%;
	max-width: 50%; 
	display: flex;
	justify-content: flex-start;
	align-items: center;
	align-content: flex-start;
	padding-top: 15px;
	padding-bottom: 15px;
}

.account-info--item:not(:last-of-type):not(:nth-of-type(8)) {
	border-bottom: 1px solid #e4e4e4;
}

.account-info-number p,
.account-info-number span {
	flex-basis: auto;
	max-width: none;
}

.account-info--item p {
	flex-basis: auto;
	max-width: none;
	flex-grow: 1;
	min-width: 180px;
}

.account-info--item span,
.account-info--item .detail {
	flex-basis: 60%;
	max-width: 60% 
}

.detail .detail-item {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	flex-basis: 100%;
	max-width: 100%; 
	line-height: 1;
	margin-bottom: 5px;
}

.detail .detail-name {
	flex-basis: auto;
	max-width: none; 
	min-width: 110px;
	line-height: 1;
	margin-right: 10px;
	flex-grow: 1;
}

.detail .detail-sum {
	flex-basis: 40px;
	max-width: 40px; 
	line-height: 1;
}

.account-info--item:nth-of-type(2n+2) {
	border-right: 1px solid #e4e4e4;
	padding-right: 25px;
}

.account-info--item:nth-of-type(2n+1) {
	padding-left: 25px;
}

.account-info-number p,
.account-info--item p {
	margin-bottom: 0;
}

.account-info-number p {
	color: #1c5bae;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 18px;
	font-weight: 700;
	line-height: 1;
	margin-right: 60px;
}

.account-info-number span, {
	color: #9a9a9b;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 18px;
	font-weight: 400;
	line-height: 1;
}

.account-info--item p {
	color: #9a9a9b;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 17px;
	font-weight: 400;
	line-height: 26px;
	padding-right: 15px;
}

.account-info--item span,
.detail .detail-item {
	color: #353638;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 17px;
	font-weight: 400;
}

.account-info--item span {
	line-height: 1;
}

.detail .detail-item {
	word-break: break-word;
}

.total-group {
	/* order: 1; */
}

@media (width <= 1199px) {
	.account-info--item {
		flex-wrap: wrap;
	}

	.account-info--item p {
		flex-basis: 100%;
		max-width: 100% 
	}

	.account-info--item span,
	.account-info--item .detail {
		flex-basis: 100%;
		max-width: 100% 
	}

	.account-info--item:nth-of-type(2n+2) {
		padding-right: 10px;
	}

	.account-info--item:nth-of-type(2n+1) {
		padding-left: 20px;
	}
}

@media (width <= 991px) {
	.account-info--item {
		flex-basis: 100%;
		max-width: 100%; 
	}

	.account-info--item:nth-of-type(8),
	.account-info--item:last-of-type {
		border-bottom: 1px solid #e4e4e4;
	}

	.account-info--item:nth-of-type(2n+2) {
		border-right: none;
	}

	.account-info--item p{
		padding-left: 15px;
	}

	.account-info .account-info--item:first-of-type {
		border-top: 1px solid red;
	}

	.account-info--item:nth-of-type(2n+2) {
		padding-right: 10px;
	}

	.account-info--item:nth-of-type(2n+1) {
		padding-left: 0;
		padding-right: 10px;
		background-color: #fcfcfc;
	}

	.account-info--item p {
		flex-basis: 50%;
		max-width: 50% 
	}

	.account-info--item span,
	.account-info--item .detail {
		flex-basis: 50%;
		max-width: 50% 
	}


}

@media (width <= 767px) {
	.account-info {
		margin-right: 20px;
		margin-bottom: 20px;
		margin-left: 20px;
		width: calc(100% - 40px);
	}

		.account-info--item p {
		flex-basis: 40%;
		max-width: 40% 
	}

	.account-info--item span,
	.account-info--item .detail {
		flex-basis: 60%;
		max-width: 60% 
	}
}

@media (width <= 641px) {
	.account-info--item p {
		flex-basis: 100%;
		max-width: 100%;  
		margin-right: 10px;
	}

	.account-info--item span,
	.account-info--item .detail {
		flex-basis: 100%;
		max-width: 100%; 
		padding-right: 0;
		margin-left: 15px;
	}

	.detail .detail-name {
		flex-basis: calc(100% - 50px);
		max-width: calc(100% - 50px);
		min-width: 110px;
		line-height: 1;
		margin-right: 10px;
		margin-left: 0;
	}

	.detail .detail-sum {
		flex-basis: 40px;
		max-width: 40px; 
		line-height: 1;
		margin-left: 0;
		padding-right: 0;
	}
}


@media (width <= 479px) {
	.account-info {
		width: calc(100% - 20px);
		margin-left: 10px;
		margin-right: 10px;
		margin-bottom: 20px;
	}

	.account-info {
		padding-left: 0;
		padding-right: 0;
	}

	.account-info--item {
		flex-wrap: wrap;
	}

	.account-info-number {
		padding-right: 20px;
		align-items: center;
	}

	.account-info--item p {
		flex-basis: 100%;
		max-width: 100%;  
		margin-right: 0;
	}

	.account-info--item span,
	.account-info--item .detail {
		flex-basis:100%;
		max-width: 100%;
		padding-right: 0;
		margin-left: 0;
		padding-left: 20px;
		display: inline-block;
		/* margin-bottom: auto; */
		/* margin-top: auto; */
	}

	.detail .detail-name {
		padding-left: 0;
		flex-basis: calc(100% - 50px);
		/* max-width: 220px; */
	}

	.detail .detail-sum {
		flex-basis: auto;
		max-width: none;
		padding-left: 0;
	}
}