.account-text {
	margin-left: 35px;
	margin-right: 35px;
	margin-bottom: 30px;
	padding-top: 20px;
	padding-bottom: 15px;
	padding-left: 30px;
	padding-right: 30px;
	background-color: #FFF;
	width: calc(100% - 70px);
}

.account-text p {
	color: #353638;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 17px;
	line-height: 26px;
	font-weight: 400;
}

.account-text span {
	display: inline-block;
	font-weight: 700;
	padding-left: 5px;
	padding-right: 5px;
}

@media (width <= 767px) {
	.account-text {
		margin-right: 20px;
		margin-bottom: 20px;
		margin-left: 20px;
		width: calc(100% - 40px);
	}
}

@media (width <= 479px) {
	.account-text {
		margin-right: 10px;
		margin-bottom: 10px;
		margin-left: 10px;
		width: calc(100% - 20px);
	}

	.account-text {
		padding-left: 20px;
		padding-right: 20px;
	}

	.account-info-number {
		padding-left: 20px;
	}

	.account-info--item p {
    padding-left: 20px;
}
}