.page-profile {
	background-color: #f0f0f0;
	padding-top: 30px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: calc(100vh - 90px);
}

.profile-info__wrapper h2 {
	color: #353638;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 24px;
	font-weight: 500;
	text-align: center;
	margin-bottom: 25px;
}

.edit-profile-info {
	max-width: 620px;
	background-color: #FFF;
	border-radius: 10px;
	margin-left: auto;
	margin-right: auto;
	padding: 40px;
}

.edit-profile-info .form-group {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-bottom: 20px;
}

.edit-profile-info label,
.edit-profile-info input,
.edit-profile-info select {
	flex-basis: auto;
	max-width: none;
}

.edit-profile-info select {
	min-height: 34px;
}

.edit-profile-info label {
	color: #353638;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 14px;
	font-weight: 500;
}

.edit-profile__wrapper .edit-profile-info input,
.edit-profile__wrapper .edit-profile-info select {
	border: 1px solid rgba(164, 164, 164, 0.3);
	border-radius: 5px;
	color: #9a9a9b;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 14px;
	font-weight: 400;
	box-shadow: none;
}

.form-input-group {
	min-width: 310px;
	max-width: 310px;
	display: flex;
	flex-direction: column; /*---*/
}

.form-input-group input,
.form-input-group select {
	flex-basis: auto;
	max-width: none;
}

.edit-profile-buttons {
	display: flex;
	justify-content: flex-end;
}

.edit-apartment {
	margin-left: 20px;
}

.edit-password-button {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 310px;
	height: 39px;
	color: white;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 14px;
	font-weight: 500;
	background-color: #7aaa50;
	border-radius: 5px;
	transition: background-color .3s;
	margin-bottom: 20px;
}

.edit-password-button:hover,
.edit-password-button:focus {
	background-color: #6f9a49;
	color: white;
}

.edit-button,
.cancel-edit-button {
	width: 150px;
	height: 39px;
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 15px;
	font-weight: 500;
	margin-top: 10px;
}

.edit-button {
	background-color: #1c5bae;
	color: white;
	transition: background-color .3s;
}

.edit-button:hover,
.edit-button:focus {
	background-color: #184d92;
	color: white;
}

.cancel-edit-button {
	border: 1px solid #c2c3c5;
	color: #353638;
	transition: border-color .3s, color .3s;
	margin-left: 10px;
}

.cancel-edit-button:hover,
.cancel-edit-button:focus {
	border-color: #1c5bae;
	color: #1c5bae;
}

.profile-select {
	display: flex;
	justify-content: space-between;
}

.profile-select select {
	flex-basis: auto;
	max-width: 150px;
}

.profile-select select + select {
	margin-left: 10px;
}

@media (width <= 641px) {
	.edit-profile-info {
		border-radius: 0;
	}

	.edit-profile-buttons {
		display: flex;
		justify-content: center;
	}

	.edit-profile-info .form-group {
		justify-content: center;
		flex-wrap: wrap;
		max-width: 310px;
		margin-left: auto;
		margin-right: auto;
	}

	.edit-profile-info label {
		margin-bottom: 10px;

	}

	.edit-profile-info label,
	.edit-profile-info input,
	.edit-profile-info select,
	.edit-profile-info .profile-select {
		flex-basis: 100%;
		max-width: 100%;
	}

	.edit-profile-info .profile-select select {
		flex-basis: calc(50% - 5px);
		max-width: calc(50% - 5px);
	}
}

@media (width <= 479px) {
	.edit-profile-info {
		padding-left: 10px;
		padding-right: 10px;
	}

	.edit-profile-info .form-group {
		justify-content: center;
		flex-wrap: wrap;
		max-width: 280px;
		margin-left: auto;
		margin-right: auto;
	}

	.form-input-group {
		min-width: 280px;
		max-width: 280px;
		display: flex;
	}

	.edit-password-button {
		width: 280px;
	}

	.edit-button,
	.cancel-edit-button {
		width: 135px;
	}
}