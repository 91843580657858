.account-cards {
	display: flex;
	justify-content: space-between;
	margin-left: 35px;
	margin-right: 35px;
	margin-bottom: 30px;
	margin-top: 30px;
	width: calc(100% - 70px);
}

.account-cards--item {
	flex-basis: calc(50% - 15px);
	max-width: calc(50% - 15px);
	display: flex;
	background-color: #FFF;
	padding-top: 25px;
	padding-right: 35px;
	padding-bottom: 25px;
	padding-left: 30px;
}

.account-cards-img {
	flex-basis: 62px;
	align-self: center;
	margin-right: 25px;
	min-width: 62px;
	max-width: 62px;
	min-height: 62px;
	max-height: 62px;
	background-color: #f14941;
	border: 1px solid transparent;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.account-cards--item:first-of-type .account-cards-img{
	background-image: linear-gradient(135deg, #203497 0%, #1c5bae 100%);
}

.account-cards--item:last-of-type .account-cards-img {
	background-image: linear-gradient(135deg, #1e62bb 0%, #2883fa 100%);
}

.account-cards-text {
	max-width: 400px;
	flex-basis: calc(100% - 90px);
}

.account-cards-text p {
	margin-bottom: 0;
	color: #9a9a9b;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 17px;
	font-weight: 400;
	line-height: 22px;
}

.account-cards-text span {
	color: #353638;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 21px;
	font-weight: 500;
	line-height: 24px;
	margin-bottom: 10px;
	display: inline-block;
}

@media (width <= 991px) {
	.account-cards--item {
		flex-wrap: wrap;
		justify-content: center;
	}

	.account-cards-img {
		margin-right: 0;
	}

	.account-cards-text {
		flex-basis: 100%;
		max-width: 100%;
		text-align: center;
		margin-top: 20px;
	}
}

@media (width <= 767px) {
	.account-cards {
		margin-left: 20px;
		margin-right: 20px;
		margin-bottom: 20px;
		width: calc(100% - 40px);
	}

	.account-cards--item {
	flex-basis: calc(50% - 10px);
	max-width: calc(50% - 10px);
	}
}

@media (width <= 641px) {
	.account-cards {
		flex-wrap: wrap;
		justify-content: center;
	}

	.account-cards--item {
		flex-basis: 100%;
		max-width: 100%;
	}

	.account-cards--item:first-of-type {
		margin-bottom: 20px;
	}
}

@media (width <= 479px) {
	.account-cards{
		margin-left: 10px;
		margin-right: 10px;
		margin-bottom: 10px;
		width: calc(100% - 20px);
	}

	.account-cards--item:first-of-type {
		margin-bottom: 10px;
	}

	.account-cards--item {
		padding-left: 20px;
		padding-right: 20px;
	}
}