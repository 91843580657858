.sign-content__wrapper {
	flex-basis: calc(100% - 485px);
	max-width: calc(100% - 485px);
	padding-top: 35px;
	padding-bottom: 35px;
	padding-right: 35px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100vh;
	background-color: #6b88e5;
	background-image: linear-gradient(42deg, #1c5bae 0%, #2278bb 100%);
	padding-left: 35px;
	overflow-y: auto;
}

.sign-content--text {
	max-width: 100%;
	width: 670px; /*---*/
	margin: auto;
}

.manual {
	color: white;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 16px;
	font-weight: 500;
	display: flex;
	-webkit-justify-content: flex-end;
	justify-content: flex-end;
	margin-top: 20px;
	display: flex;
}

.manual svg {
	order: -1;
	margin-right: 15px;
}

.manual:hover,
.manual:focus {
	color: white;
}

.text-note {
	color: white;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 28px;
	line-height: 43px;
	font-weight: 300;
	text-align: center;
	margin-top: 0;
	margin-bottom: 45px;
}

.text-note span {
	font-weight: 500;
	margin-left: 7px;
	margin-right: 7px;
}

.text-title {
	color: white;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 21px;
	font-weight: 500;
	line-height: 32px;
	text-align: center;
	margin-bottom: 20px;
}

.text-list {
	list-style: none;
	padding-left: 0;
}

.text-list li {
	color: white;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 17px;
	line-height: 26px;
	margin-bottom: 20px;
	padding-left: 50px;
	position: relative;
}

.text-list {
	counter-reset: my-counter;
}

.text-list li::before {
	position: absolute;
	top: 0;
	left: 0;
	width: 32px;
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	content: counter(my-counter);
	counter-increment: my-counter;
	line-height: 1;
	background-color: white;
	box-shadow: -10px 0 20px rgba(48, 48, 49, 0.26);
	border-radius: 50%;
	color: #2f5ae4;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 21px;
	font-weight: 300;
}

.text-list span {
	margin-left: 7px;
	margin-right: 7px;
}

li:nth-of-type(2) .text-list-bold {
	margin-left: 0;
}

.text-list-bold {
	font-weight: 700;
}

.text-list-italic {
	font-style: italic;
}

.sign-content--footer {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.copyright,
nav {
	flex-basis: auto;
	max-width: none;
}

.copyright,
.sign-content--footer ul li a {
	opacity: 0.6;
	color: white;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 14px;
}

.sign-content--footer ul {
	display: flex;
	justify-content: flex-end;
	list-style: none;
	padding-left: 0;
}

.sign-content--footer ul li:not(:first-of-type) {
	margin-left: 10px;
}

.sign-content--footer ul li:not(:last-of-type) {
	margin-right: 10px;
}

@media (width <= 1199px) {
	.sign-content--footer {
		flex-wrap: wrap;
	}

	.copyright {
		width: 100%;
		text-align: center;
		margin-bottom: 0;
		order: 1;
	}

	.sign-content--footer nav {
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 10px;
	}
}

@media (width <= 991px) {
	.sign-content__wrapper {
		flex-basis: 100%;
		max-width: 100%;
		padding-left: 0;
		padding-right: 0;
	}

	.sign-content--text {
		max-width: 670px;
		margin: auto;
		padding-left: 20px;
		padding-right: 20px;
	}

	.manual {
		position: absolute;
		top: 20px;
		right: 20px;
		color: #1c5bae;
	}

	.manual:hover,
	.manual:focus {
		color: #1c5bae;
	}

	.manual svg path {
		fill: #1c5bae;
	}

	.text-note {
		max-width: 410px;
		margin-left: auto;
		margin-right: auto;
	}

	.sign-content--footer {
		flex-wrap: nowrap;
		padding-top: 30px;
		border-top: 1px solid #e3e3e3;
		padding-left: 20px;
		padding-right: 20px;
	}

	.copyright {
		width: auto;
		text-align: left;
		margin-bottom: 0;
		order: -1;
	}

	.sign-content--footer nav {
		margin-left: auto;
		margin-right: 0;
		margin-bottom: 0;
	}
}

@media (width <= 767px) {
	.sign-content--text {
	max-width: 100%;
	width: auto; /*---*/
	margin: auto;
}

		.sign-content--footer {
		flex-wrap: wrap;
	}

	.copyright {
		width: 100%;
		text-align: center;
		margin-bottom: 0;
		order: 1;
	}

	.sign-content--footer nav {
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 10px;
	}

	.text-note {
  	font-size: 23px;
  	line-height: 32px;
  	margin-bottom: 35px;
  	}
}

@media (width <= 479px) {
	.sign-content__wrapper {
		padding-bottom: 20px;
	}

	.manual {
    position: absolute;
    top: 130px;
    left: 50%;
    transform: translateX(-50%);
    right: auto;
    margin-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 40px);
    max-width: 310px;
    height: 43px;
    border: 1px solid #c2c3c5;
    border-radius: 22px;
    font-weight: 400;
    color: #353638;
    transition: border-color .3s, color .3s;
  }

  .manual:hover,
  .manual:focus {
  	color: #1e66b3;
  	border-color: #1e66b3;
  }

  .manual svg path {
  	fill: #353638;
  	transition: fill .3s;
  }

  .manual:hover svg path,
  .manual:focus svg path {
  	fill: #1e66b3;
  }

  .sign-content--footer ul {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	list-style: none;
	padding-left: 0;
}

  .sign-content--footer ul li {
  	flex-basis: 100%;
  	max-width: 100%;
  	text-align: center;
  }

  .sign-content--footer ul li:not(:first-of-type) {
	margin-left: 0;
}

.sign-content--footer ul li:not(:last-of-type) {
	margin-right: 0;
	margin-bottom: 15px;
}

.sign-content--footer ul li a {
	color: white;
	font-size: 16px;
	font-weight: 500;
	line-height: 36px;
	text-decoration: underline;
	opacity: 1;
}

  .copyright {
		order: 0;
		margin-bottom: 30px;
		color: white;
		font-size: 16px;
		font-weight: 400;
		opacity: 1;
	}

	.sign-content--footer nav {
		margin-bottom: 0;
	}

  .text-note {
  	font-size: 23px;
  	line-height: 32px;
  	margin-bottom: 30px;
  }

  .sign-sidebar--forms h2 {
  	font-size: 23px;
  }
}