/* @import url(./blocks/header-nav.css); */
/* @import url(./blocks/index--video.css); */
/* @import url(./blocks/service-category.css); */
@import url(./blocks/sign-wrapper);
@import url(./blocks/sign-sidebar--sign-up);
@import url(./blocks/sign-sidebar--sign-in);
@import url(./blocks/scrollbar);
@import url(./blocks/sign-content);
@import url(./blocks/modal-manual);
@import url(./blocks/modal-fogot-password);
@import url(./blocks/account__wrapper);
@import url(./blocks/general-header);
@import url(./blocks/header-dropdown-menu);
@import url(./blocks/general-content__wrapper);
@import url(./blocks/general-sidebar);
@import url(./blocks/responsive-sidebar);
@import url(./blocks/account-content);
@import url(./blocks/general-footer);
@import url(./blocks/general-form);
@import url(./blocks/account-current-balance);
@import url(./blocks/account-cards);
@import url(./blocks/account-text);
@import url(./blocks/account-info);
@import url(./blocks/account__wrapper);
@import url(./blocks/modal-print);
@import url(./blocks/table-search);
@import url(./blocks/table-info);
@import url(./blocks/report-info);
@import url(./blocks/edit-profile-info);
@import url(./blocks/payment-for-gas-info);
@import url(./blocks/edit-page-footer);
@import url(./blocks/invoice-info);
@import url(./blocks/page-text);
@import url(./blocks/privacy-policy-info);
@import url(./blocks/contacts-info);
@import url(./blocks/404-info);
@import url(./blocks/reporting-period-info);
@import url(./blocks/edge-style);
@import url(./blocks/personal-account-alert);