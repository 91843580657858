.account-content__wrapper,
.static-info-content__wrapper,
.report-content__wrapper {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	flex-basis: calc(100% - 300px);
	background-color: #f0f0f0;
	height: 100%;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}

.general-form,
.account-cards,
.account-text,
.account-info,
.general-footer,
.table-info, 
.table-search,
.report-info__wrapper {
	flex-basis: 100%;
	max-width: 100%;
}

@media (width <= 991px) {
	.account-content__wrapper,
	.static-info-content__wrapper,
	.report-content__wrapper {
		flex-basis: 100%;
	}
}