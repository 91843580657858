.general-content__wrapper {
	display: flex;
	width: 100%;
	flex-grow: 1;
	align-items: flex-start;
	height: calc(100% - 90px);
}

@media (width <= 991px) {
	.general-content__wrapper {
		padding-top: 50px;
	}
}

@media (width <= 767px) {
	.general-content__wrapper {
	height: auto;
}
}
/* 
@media (width <= 641px) {
	.general-content__wrapper {
	height: calc(100% - 160px);
}
} */