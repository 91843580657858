.table-info {
	background-color: #FFF;
	width: calc(100% - 70px);
	margin-right: 35px;
	margin-bottom: auto; /*---*/
	margin-left: 35px;
	max-width: calc(100% - 70px);
}

.table-info::after {
	display: block;
	content: "";
	height: 30px;
	width: 100%;
	background-color: #f0f0f0;
}

.table-info__wrapper {
	padding-top: 15px;
	padding-bottom: 15px;
	margin-left: 30px;
	margin-right: 30px;
	overflow-x: auto;
}

.table-info__wrapper .table {
	margin-bottom: 0;
	min-width: 1025px;
}

.table-info__wrapper tr:first-of-type {
	border: none;
}

.table > thead > tr > th, 
.table > tbody > tr > th{
	border-top: none;
}

.table-info__wrapper tr th:first-of-type,  
.table-info__wrapper tr td:first-of-type {
	padding-left: 0;
}

.table-info__wrapper .table tr th,  
.table-info__wrapper .table tr td {
	padding-top: 20px;
	padding-bottom: 20px;
	text-align: right;
	max-width: 110px;
}

.table-info__wrapper .table tr:first-of-type th {
	padding-top: 10px;
}

.table-info__wrapper .table tr:last-of-type td {
	padding-bottom: 10px;
}

.table-info__wrapper .table tr th {
	color: #353638;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 17px;
	font-weight: 700;
	line-height: 26px;
	text-align: right;
}

.table-info__wrapper .table tr td {
	color: #353638;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 17px;
	font-weight: 400;
	line-height: 26px;
} 

/* .table-info__wrapper .table tr th:last-of-type, 
.table-info__wrapper .table tr td:last-of-type {
	color: #1c5bae;
} */

.table-info__wrapper .table tr .color-red {
	color: red;
}

.table-info__wrapper .table tr .color-green {
	color: green;
}

@media (width <= 767px) {
	.table-info {
		margin-right: 20px;
		margin-left: 20px;
		width: calc(100% - 20px);
		max-width: calc(100% - 40px);
	}
}

@media (width <= 641px) {
	.table-info__wrapper {
		padding-left: 0;
		padding-right: 0;
		margin-left: 15px;
		margin-right: 15px;
	}

	.table-info__wrapper .table tr th,  
	.table-info__wrapper .table tr td {
		padding-top: 15px;
		padding-bottom: 15px;
	}
}

@media (width <= 479px) {
	.table-info {
		margin-right: 10px;
		margin-left: 10px;
		width: calc(100% - 20px);
		max-width: calc(100% - 20px);
	}
}