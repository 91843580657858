.contacts-title {
	text-align: center;
	margin-top: 0;
	line-height: normal;
	color: #353638;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 24px;
	font-weight: 500;
	margin-bottom: 25px;
}

.contacts__wrapper {
	display: flex;
	justify-content: space-between;
	width: 100%;
	background-color: #FFF;
	padding: 40px;
	margin-bottom: 20px;
	flex-grow: 1;
	max-width: 1340px;
	margin-left: auto;
	margin-right: auto;
}

.contacts-info,
.contacts-feedback__wrapper {
	flex-basis: 50%;
	max-width: 50%;
}

.contacts-info {
	padding-right: 30px;
}

.contacts-info--item {
	margin-bottom: 5px;
}

.contacts-info h3,
.contacts-info p,
.contacts-info a {
	color: #353638;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	line-height: 26px;
	margin-bottom: 15px;
}

.contacts-info h3 {
	font-size: 21px;
	font-weight: 700;
}

.contacts-info p,
.contacts-info a {
	font-size: 17px;
	font-weight: 400;
}

.contacts-info a {
	padding-bottom: 2px;
	border-bottom: 1px solid #1c5bae;
	transition: color .3s;
}

.contacts-info a:hover,
.contacts-info a:focus {
	color: #1c5bae;
}

.contacts-feedback__wrapper {
	display: flex;
	justify-content: flex-start;
	padding-left: 30px;
}

.contacts-feedback {
	flex-basis: 100%;
	max-width: 100%;
}

.contacts-feedback h3 {
	color: #1c5bae;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 21px;
	font-weight: 700;
	line-height: 26px;
	margin-bottom: 25px;
}

.contacts-feedback .form-group {
	margin-bottom: 20px;
}

.contacts-feedback input,
.contacts-feedback textarea {
	width: 312px;
	border: 1px solid rgba(164, 164, 164, 0.3);
	border-radius: 5px;
	color: #9a9a9b;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 14px;
	font-weight: 400;
	background-color: #FFF;
}

.contacts-feedback input {
	height: 37px;
}

.contacts-feedback textarea {
	height: 77px;
	resize: none;
}

.feedback-button a {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 150px;
	height: 39px;
	background-color: #1c5bae;
	border-radius: 5px;
	color: white;
	font-family: 'Helvetica Neue', 'Roboto', sans-serif;
	font-size: 15px;
	font-weight: 500;
	transition: color .3s, background-color .3s;
}

.feedback-button a:hover,
.feedback-button a:focus {
	color: white;
	background-color: #184D92;
}

@media (width <= 1199px) {
	.contacts-info {
		flex-basis: calc(100% - 350px);
		max-width: calc(100% - 350px);
		padding-right: 0;
	}

	.contacts-feedback__wrapper {
		justify-content: flex-end;
		padding-left: 0;
		flex-basis: 350px;
		max-width: 350px;
	}
}

@media (width <= 767px) {
	.contacts__wrapper {
		flex-wrap: wrap;
	}

	.contacts-info {
		flex-basis: 100%;
		max-width: 500px;
		padding-right: 0;
		margin-left: auto;
		margin-right: auto;
	}

	.contacts-feedback__wrapper {
		justify-content: flex-start;
		padding-left: 0;
		flex-basis: 100%;
		max-width: 500px;
		margin-left: auto;
		margin-right: auto;
		
	}
}

@media (width <= 479px) {
	.contacts__wrapper {
	padding: 20px;
	margin-bottom: 0;
	}
	
	.contacts-feedback input,
	.contacts-feedback textarea {
		width: 100%;
		max-width: 100%;
	}
}